/**
 * Simple client for Warnet API
 */
class WarnetClient {
  constructor(csrfToken) {
    this.csrfToken = csrfToken;
  }

  createImageFromIo = (file, onProgress = undefined) => {
    const body = new FormData();
    body.append('attachment', file);

    const progressCallback = onProgress || (() => {});

    return this.call({
      path: '/api/images?scheme=io',
      method: 'POST',
      body,
      progressCallback,
    });
  };

  createImageFromUrl = (url) => {
    const body = JSON.stringify({ url });

    const progressCallback = () => {};

    const configureRequest = (request) => {
      request.setRequestHeader('Content-Type', 'application/json');
    };

    return this.call({
      path: '/api/images?scheme=url',
      method: 'POST',
      body,
      progressCallback,
      configureRequest,
    });
  };

  createImageFromId = (imageId) => {
    const body = JSON.stringify({ id: imageId });

    const progressCallback = () => {};

    const configureRequest = (request) => {
      request.setRequestHeader('Content-Type', 'application/json');
    };

    return this.call({
      path: '/api/images?scheme=id',
      method: 'POST',
      body,
      progressCallback,
      configureRequest,
    });
  };

  createPost = (post) => {
    const body = JSON.stringify(post);

    const progressCallback = () => {};

    const configureRequest = (request) => {
      request.setRequestHeader('Content-Type', 'application/json');
    };

    return this.call({
      path: '/api/posts',
      method: 'POST',
      body,
      progressCallback,
      configureRequest,
    });
  };

  updatePost = (postId, post) => {
    const body = JSON.stringify(post);

    const progressCallback = () => {};

    const configureRequest = (request) => {
      request.setRequestHeader('Content-Type', 'application/json');
    };

    return this.call({
      path: `/api/posts/${postId}`,
      method: 'PATCH',
      body,
      progressCallback,
      configureRequest,
    });
  };

  discardPost = (postId) => {
    const body = '';

    const progressCallback = () => {};

    const configureRequest = (request) => {
      request.setRequestHeader('Content-Type', 'application/json');
    };

    return this.call({
      path: `/api/posts/${postId}/discard`,
      method: 'PATCH',
      body,
      progressCallback,
      configureRequest,
    });
  };

  undiscardPost = (postId) => {
    const body = '';

    const progressCallback = () => {};

    const configureRequest = (request) => {
      request.setRequestHeader('Content-Type', 'application/json');
    };

    return this.call({
      path: `/api/posts/${postId}/undiscard`,
      method: 'PATCH',
      body,
      progressCallback,
      configureRequest,
    });
  };

  destroyPost = (postId) => {
    const body = '';

    const progressCallback = () => {};

    const configureRequest = (request) => {
      request.setRequestHeader('Content-Type', 'application/json');
    };

    return this.call({
      path: `/api/posts/${postId}`,
      method: 'DELETE',
      body,
      progressCallback,
      configureRequest,
    });
  };

  createComment = (postId, comment) => {
    const body = JSON.stringify(comment);

    const progressCallback = () => {};

    const configureRequest = (request) => {
      request.setRequestHeader('Content-Type', 'application/json');
    };

    return this.call({
      path: `/api/posts/${postId}/comments`,
      method: 'POST',
      body,
      progressCallback,
      configureRequest,
    });
  };

  discardComment = (commentId) => {
    const body = '';

    const progressCallback = () => {};

    const configureRequest = (request) => {
      request.setRequestHeader('Content-Type', 'application/json');
    };

    return this.call({
      path: `/api/comments/${commentId}/discard`,
      method: 'PATCH',
      body,
      progressCallback,
      configureRequest,
    });
  };

  undiscardComment = (commentId) => {
    const body = '';

    const progressCallback = () => {};

    const configureRequest = (request) => {
      request.setRequestHeader('Content-Type', 'application/json');
    };

    return this.call({
      path: `/api/comments/${commentId}/undiscard`,
      method: 'PATCH',
      body,
      progressCallback,
      configureRequest,
    });
  };

  destroyComment = (commentId) => {
    const body = '';

    const progressCallback = () => {};

    const configureRequest = (request) => {
      request.setRequestHeader('Content-Type', 'application/json');
    };

    return this.call({
      path: `/api/comments/${commentId}`,
      method: 'DELETE',
      body,
      progressCallback,
      configureRequest,
    });
  };

  updateDonation = (donationId, donation) => {
    const body = JSON.stringify(donation);

    const progressCallback = () => {};

    const configureRequest = (request) => {
      request.setRequestHeader('Content-Type', 'application/json');
    };

    return this.call({
      path: `/api/donations/${donationId}`,
      method: 'PATCH',
      body,
      progressCallback,
      configureRequest,
    });
  };

  discardDonation = (donationId) => {
    const body = '';

    const progressCallback = () => {};

    const configureRequest = (request) => {
      request.setRequestHeader('Content-Type', 'application/json');
    };

    return this.call({
      path: `/api/donations/${donationId}/discard`,
      method: 'PATCH',
      body,
      progressCallback,
      configureRequest,
    });
  };

  call = ({
    path,
    method,
    body,
    progressCallback,
    configureRequest,
  }) => new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();

    // request event sequence:
    // request.onloadstart ->
    // request.upload.onloadstart ->
    // request.upload.onprogress ->
    // request.upload.onloadend ->
    // request.onprogress ->
    // request.onloadend

    request.upload.onloadstart = () => {
      progressCallback(0);
    };

    request.upload.onprogress = (event) => {
      if (!event.lengthComputable) return;

      progressCallback(Math.round((event.loaded * 100) / event.total));
    };

    request.upload.onloadend = () => {
      progressCallback(100);
    };

    request.onloadend = () => {
      const isRequestSuccessful = request.status >= 200 && request.status < 300;

      if (!isRequestSuccessful) {
        reject(new Error(`Request was unsuccessful (status=${request.status})`));
        return;
      }

      try {
        resolve(JSON.parse(request.response));
      } catch (e) {
        reject(new Error('Request was successful but response body has invalid format'));
      }
    };

    request.open(method, path, true);
    request.setRequestHeader('Accept', 'application/json');
    request.setRequestHeader('X-CSRF-Token', this.csrfToken);

    if (configureRequest) configureRequest(request);

    request.send(body);
  });
}

export default WarnetClient;
